import React from 'react';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
      root: {
            height: 14,
            borderRadius: 5,
      },
      colorPrimary: {
            backgroundColor:
                  theme.palette.grey[
                        theme.palette.type === 'light' ? 200 : 700
                  ],
      },
      bar: {
            borderRadius: 5,
            backgroundColor: '#CF8F08',
      },
}))(LinearProgress);

export default function ListingProgress({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
}) {
      const outerClasses = classNames(
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'testimonial-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const sectionHeader = {
            title: 'NFT Listing Progress',
            paragraph: '',
      };

      return (
            <section {...props} className={outerClasses}>
                  <div className="container">
                        <div className={innerClasses}>
                              <SectionHeader
                                    data={sectionHeader}
                                    className="center-content"
                              />
                              <Box
                                    sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                    }}
                              >
                                    <Box sx={{ width: '100%', mr: 1 }}>
                                          <BorderLinearProgress
                                                variant="determinate"
                                                value={(30 / 1008) * 100}
                                                {...props}
                                          />
                                    </Box>
                                    <Box
                                          sx={{
                                                minWidth: 35,
                                                textAlign: 'center',
                                          }}
                                    >
                                          <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                style={{ color: '#CF8F08' }}
                                          >
                                                30 / 1008 NFT (
                                                {`${Math.round(
                                                      (30 / 1008) * 100,
                                                )}%`}
                                                )
                                          </Typography>
                                    </Box>
                              </Box>
                        </div>
                  </div>
            </section>
      );
}
