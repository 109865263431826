import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import feature1 from './../../assets/images/feature-tile-icon-01.svg';
import feature2 from './../../assets/images/feature-tile-icon-02.svg';
import feature3 from './../../assets/images/feature-tile-icon-03.svg';
import feature4 from './../../assets/images/feature-tile-icon-04.svg';

const propTypes = {
      ...SectionTilesProps.types,
};

const defaultProps = {
      ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
}) => {
      const outerClasses = classNames(
            'features-tiles section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'features-tiles-inner section-inner pt-0',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const tilesClasses = classNames(
            'tiles-wrap center-content',
            pushLeft && 'push-left',
      );

      const sectionHeader = {
            title: 'SAVING BORNEO WILDLIFE',
            paragraph: `- features 1008 unique pictures
                  presenting animal species
                  characteristic for Borneo. Many of
                  them occur only on Borneo and are
                  unfortunately in danger of extinction.
                  10% of sales of our pictures will go
                  to the DGFC and will be used to
                  support their activity aimed at saving
                  endangered animal species, a task in
                  which they already have many
                  achievements. The rest of the obtained funds will be
                  used to develop the BEKANTAN brand
                  (which also supports the DGFC), which
                  manufactures a healthy energy drink
                  and other products without artificial
                  additives, sugar, etc., which
                  emphasises food quality as a key
                  element of our quality of life.`,
      };

      return (
            <section {...props} className={outerClasses}>
                  <div className="container" id="saving">
                        <div className={innerClasses}>
                              <SectionHeader
                                    data={sectionHeader}
                                    className="center-content"
                              />
                              <div className={tilesClasses}>
                                    <div className="tiles-item reveal-from-bottom">
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={feature1}
                                                                  alt="Features tile icon 01"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Buy 1 NFT
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            Each buyer of at
                                                            least one of the
                                                            collectible pictures
                                                            prepared will
                                                            additionally receive
                                                            100 BKN tokens per
                                                            picture
                                                      </p>
                                                </div>
                                          </div>
                                    </div>

                                    <div
                                          className="tiles-item reveal-from-bottom"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={feature2}
                                                                  alt="Features tile icon 02"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Buy 3 NFT
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            Moreover buyers of 3
                                                            pictures will get a
                                                            unique t-shirt with
                                                            the SAVING BORNEO
                                                            WILDLIFE logo and
                                                            will receive 100 BKN
                                                            tokens per picture
                                                      </p>
                                                </div>
                                          </div>
                                    </div>

                                    <div
                                          className="tiles-item reveal-from-bottom"
                                          data-reveal-delay="400"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={feature3}
                                                                  alt="Features tile icon 03"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Buy 5 NFT
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            buyers of 5 pictures
                                                            will get a
                                                            sweatshirt with the
                                                            SAVING BORNEO
                                                            WILDLIFE logo and
                                                            will receive 100 BKN
                                                            tokens per picture
                                                      </p>
                                                </div>
                                          </div>
                                    </div>

                                    <div className="tiles-item reveal-from-bottom">
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={feature4}
                                                                  alt="Features tile icon 04"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Buy 8 or more NFT
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            while buyers of 8 or
                                                            more pictures will
                                                            get a t-shirt and
                                                            sweatshirt from our
                                                            unique, limited
                                                            series and will also
                                                            receive samples of
                                                            every new BEKANTAN
                                                            product and will
                                                            receive 100 BKN
                                                            tokens per picture
                                                      </p>
                                                </div>
                                          </div>
                                    </div>

                                    {/* <div
                                          className="tiles-item reveal-from-bottom"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={require('./../../assets/images/feature-tile-icon-05.svg')}
                                                                  alt="Features tile icon 05"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Use of funds
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            The rest of the
                                                            obtained funds will
                                                            be used to develop
                                                            the BEKANTAN brand
                                                            (which also supports
                                                            the DGFC), which
                                                            manufactures a
                                                            healthy energy drink
                                                            and other products
                                                            without artificial
                                                            additives, sugar,
                                                            etc., which
                                                            emphasises food
                                                            quality as a key
                                                            element of our
                                                            quality of life.
                                                      </p>
                                                </div>
                                          </div>
                                    </div> */}

                                    {/* <div
                                          className="tiles-item reveal-from-bottom"
                                          data-reveal-delay="400"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="features-tiles-item-header">
                                                      <div className="features-tiles-item-image mb-16">
                                                            <Image
                                                                  src={require('./../../assets/images/feature-tile-icon-06.svg')}
                                                                  alt="Features tile icon 06"
                                                                  width={64}
                                                                  height={64}
                                                            />
                                                      </div>
                                                </div>
                                                <div className="features-tiles-item-content">
                                                      <h4 className="mt-0 mb-8">
                                                            Use of funds
                                                      </h4>
                                                      <p className="m-0 text-sm">
                                                            The rest of the
                                                            obtained funds will
                                                            be used to develop
                                                            the BEKANTAN brand
                                                            (which also supports
                                                            the DGFC), which
                                                            manufactures a
                                                            healthy energy drink
                                                            and other products
                                                            without artificial
                                                            additives, sugar,
                                                            etc., which
                                                            emphasises food
                                                            quality as a key
                                                            element of our
                                                            quality of life.
                                                      </p>
                                                </div>
                                          </div>
                                    </div> */}
                              </div>
                        </div>
                  </div>
            </section>
      );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
