import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import DgfcLogo from '../../assets/images/DGFC_black.png';
import BekantanCoinLogo from '../../assets/images/bekantan_coin.png';
import BekantanEnergyLogo from '../../assets/images/bekantan-drink-png.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const propTypes = {
      ...SectionSplitProps.types,
};

const defaultProps = {
      ...SectionSplitProps.defaults,
};

const Partners = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
}) => {
      const outerClasses = classNames(
            'features-split section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'features-split-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const splitClasses = classNames(
            'split-wrap',
            invertMobile && 'invert-mobile',
            invertDesktop && 'invert-desktop',
            alignTop && 'align-top',
      );

      const sectionHeader = {
            title: 'Our Partners',
            paragraph:
                  'We create our project with our partners: Bekantan and DGFC',
      };

      const matches = useMediaQuery('(min-width:600px)');

      console.log(matches, 'matches');

      return (
            <section {...props} className={outerClasses}>
                  <div className="container">
                        <div className={innerClasses}>
                              <SectionHeader
                                    data={sectionHeader}
                                    className="center-content"
                              />
                              <div
                                    style={{
                                          display: matches ? 'flex' : 'block',
                                          alignItems: 'center',
                                          direction: matches ? 'row' : 'column',
                                          justifyContent: 'space-around',
                                          textAlign: 'center',
                                    }}
                              >
                                    <div>
                                          <a href="https://www.dgfc.life/home/">
                                                <img
                                                      style={{
                                                            height: '170px',
                                                      }}
                                                      src={DgfcLogo}
                                                />
                                          </a>
                                    </div>
                                    <div>
                                          <a href="https://bekantan.io/">
                                                <img
                                                      style={{
                                                            height: '170px',
                                                      }}
                                                      src={BekantanCoinLogo}
                                                />
                                          </a>
                                    </div>
                                    <div>
                                          <a href="https://bekantan.io/">
                                                <img
                                                      style={{
                                                            height: '170px',
                                                      }}
                                                      src={BekantanEnergyLogo}
                                                />
                                          </a>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
