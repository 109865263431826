import React from 'react';
// import sections
import Main from '../components/sections/Main';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import NftExamples from '../components/sections/NftExamples';
import Cta from '../components/sections/Cta';
import GalleryMain from '../components/sections/GalleryMain';

const Gallery = () => {
      return (
            <>
                  <GalleryMain />
            </>
      );
};

export default Gallery;
