import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import SectionHeader from './partials/SectionHeader';
import Jungle from '../../assets/images/borneo-forest.png';
import OneThreeThree from '../../assets/images/collection/133.png';
import OneFourEight from '../../assets/images/collection/148.png';
import ThreeTwoThree from '../../assets/images/collection/323.png';
import TwoSevenThree from '../../assets/images/collection/273.png';
import FourTwoThree from '../../assets/images/collection/423.png';
import TwoFiveNine from '../../assets/images/collection/259.png';
import { URLS } from '../../utils/consts';

const propTypes = {
      ...SectionTilesProps.types,
};

const defaultProps = {
      ...SectionTilesProps.defaults,
};

const NftExamples = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
}) => {
      const outerClasses = classNames(
            'testimonial section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'testimonial-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

      const sectionHeader = {
            title: 'Our NFT collection',
            paragraph: '',
      };

      return (
            <section {...props} className={outerClasses}>
                  <div className="container">
                        <div className={innerClasses}>
                              <SectionHeader
                                    data={sectionHeader}
                                    className="center-content"
                              />
                              <div className={tilesClasses}>
                                    <div
                                          className="tiles-item reveal-from-right"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={ThreeTwoThree}
                                                            alt="NFT 323"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            Bornean Bristlehead
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #323
                                                      </span>
                                                </div>
                                          </div>
                                    </div>

                                    <div className="tiles-item reveal-from-bottom">
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={OneFourEight}
                                                            alt="NFT 148"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            Great Argus
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #148
                                                      </span>
                                                </div>
                                          </div>
                                    </div>

                                    <div
                                          className="tiles-item reveal-from-left"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={OneThreeThree}
                                                            alt="NFT 133"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            Fruit Bat
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #133
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className={tilesClasses}>
                                    <div
                                          className="tiles-item reveal-from-right"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={TwoSevenThree}
                                                            alt="NFT 273"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            Wrinkled hornbill
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #273
                                                      </span>
                                                </div>
                                          </div>
                                    </div>

                                    <div className="tiles-item reveal-from-bottom">
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={TwoFiveNine}
                                                            alt="NFT 259"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            Dollarbird
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #259
                                                      </span>
                                                </div>
                                          </div>
                                    </div>

                                    <div
                                          className="tiles-item reveal-from-left"
                                          data-reveal-delay="200"
                                    >
                                          <div className="tiles-item-inner">
                                                <div className="testimonial-item-content">
                                                      <img
                                                            src={FourTwoThree}
                                                            alt="NFT 423"
                                                      />
                                                </div>
                                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                      <span className="testimonial-item-name text-color-high">
                                                            False Gharial
                                                      </span>
                                                      <span className="text-color-low">
                                                            {' '}
                                                            /{' '}
                                                      </span>
                                                      <span className="testimonial-item-link">
                                                            #423
                                                      </span>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div>
                                    <Button
                                          tag="a"
                                          color="dark"
                                          wideMobile
                                          href={URLS.OPEN_SEA_URL}
                                    >
                                          See All
                                    </Button>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

NftExamples.propTypes = propTypes;
NftExamples.defaultProps = defaultProps;

export default NftExamples;
