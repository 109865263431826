import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Roadmap from '../../assets/images/roadmap.jpg';
import { URLS } from '../../utils/consts';
import SectionHeader from './partials/SectionHeader';
import presentation from '../../assets/pdf/prezentacja_borneo.pdf';

const propTypes = {
      ...SectionProps.types,
};

const defaultProps = {
      ...SectionProps.defaults,
};

const Main = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
}) => {
      const [videoModalActive, setVideomodalactive] = useState(false);

      const openModal = (e) => {
            e.preventDefault();
            setVideomodalactive(true);
      };

      const closeModal = (e) => {
            e.preventDefault();
            setVideomodalactive(false);
      };

      const outerClasses = classNames(
            'hero section center-content',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'hero-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const sectionHeader = {
            title: 'Our Roadmap',
            paragraph: ``,
      };

      return (
            <section {...props} className={outerClasses}>
                  <div className="container-sm" id="about">
                        <div className={innerClasses}>
                              <div className="hero-content">
                                    <h1
                                          className="mt-0 mb-16 reveal-from-bottom"
                                          data-reveal-delay="200"
                                    >
                                          <span className="text-color-primary">
                                                Saving Borneo Wildlife
                                          </span>
                                    </h1>
                                    <div className="container-xs">
                                          <p
                                                className="m-0 mb-32 reveal-from-bottom"
                                                data-reveal-delay="400"
                                          >
                                                is an innovative idea to involve
                                                the newest trends from the
                                                cryptocurrency world in wildlife
                                                protection. The idea was born
                                                out of the cooperation
                                                undertaken between BEKANTAN
                                                (which produces healthy drinks)
                                                and the DANAU GIRANG FIELD
                                                CENTRE, a research institution
                                                located in the middle of North
                                                BORNEO’s rainforests. The Centre
                                                is distinguished for its daily
                                                fight to protect wildlife.
                                          </p>
                                          <div
                                                className="reveal-from-bottom"
                                                data-reveal-delay="600"
                                          >
                                                <ButtonGroup>
                                                      <Button
                                                            tag="a"
                                                            color="primary"
                                                            wideMobile
                                                            href={
                                                                  URLS.OPEN_SEA_URL
                                                            }
                                                      >
                                                            Join Sale
                                                      </Button>
                                                      <Button
                                                            tag="a"
                                                            color="dark"
                                                            wideMobile
                                                            href={presentation}
                                                            download
                                                      >
                                                            Presentation
                                                      </Button>
                                                </ButtonGroup>
                                          </div>
                                    </div>
                              </div>
                              <div
                                    className="hero-figure reveal-from-bottom illustration-element-01"
                                    data-reveal-value="20px"
                                    data-reveal-delay="800"
                                    id="roadmap"
                              >
                                    <SectionHeader
                                          data={sectionHeader}
                                          className="center-content"
                                    />
                                    <div id="roadmap">
                                          <Image
                                                className="has-shadow"
                                                src={Roadmap}
                                                alt="Hero"
                                                width={896}
                                                height={504}
                                          />
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
