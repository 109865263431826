import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import presentation from '../../../assets/pdf/prezentacja_borneo.pdf';

const FooterNav = ({ className, ...props }) => {
      const classes = classNames('footer-nav', className);

      return (
            <nav {...props} className={classes}>
                  <ul className="list-reset">
                        <li>
                              <HashLink to="/#about">ABOUT</HashLink>
                        </li>
                        <li>
                              <Link to="#0">SAVING BORNEO WILDLIFE</Link>
                        </li>
                        <li>
                              <HashLink to="/#roadmap">ROADMAP</HashLink>
                        </li>
                        {/* <li>
                              <Link to="#0">GALLERY</Link>
                        </li> */}
                        <li>
                              <a href={presentation} download>
                                    PRESENTATION
                              </a>
                        </li>
                  </ul>
            </nav>
      );
};

export default FooterNav;
