import React from 'react';
// import sections
import Main from '../components/sections/Main';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import NftExamples from '../components/sections/NftExamples';
import Cta from '../components/sections/Cta';
import Partners from '../components/sections/Partners';
import ListingProgress from '../components/sections/ListingProgress';

const Home = () => {
      return (
            <>
                  <Main className="illustration-section-01" />
                  <FeaturesTiles />
                  <NftExamples topDivider />
                  <ListingProgress />
                  <FeaturesSplit
                        invertMobile
                        topDivider
                        imageFill
                        className="illustration-section-02"
                  />
                  <Partners />
                  <Cta split />
            </>
      );
};

export default Home;
