import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
      ...SectionSplitProps.types,
};

const defaultProps = {
      ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
}) => {
      const outerClasses = classNames(
            'features-split section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'features-split-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const splitClasses = classNames(
            'split-wrap',
            invertMobile && 'invert-mobile',
            invertDesktop && 'invert-desktop',
            alignTop && 'align-top',
      );

      const sectionHeader = {
            title: 'OUR GOALS AND IDEAS',
            paragraph: ' ',
      };

      return (
            <section {...props} className={outerClasses}>
                  <div className="container">
                        <div className={innerClasses}>
                              <SectionHeader
                                    data={sectionHeader}
                                    className="center-content"
                              />
                              <div className={splitClasses}>
                                    <div className="split-item">
                                          <div
                                                className="split-item-content center-content-mobile reveal-from-left"
                                                data-reveal-container=".split-item"
                                          >
                                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                                      WHY NFT's?
                                                </div>
                                                {/* <h3 className="mt-0 mb-12">
                                                NFTs and the opportunities they offer are crucial in the modern world: not only for artists, but also for persons involved in other valuable endeavours. Our project aims to draw attention to what is happening to wildlife in today’s world, and to the fact that given current trends, so destructive for the environment, our children will no longer have the opportunity to see animals such as elephants and thousands of other more or less popular species in their natural habitats.
                                                </h3> */}
                                                <p className="m-0">
                                                      NFTs and the opportunities
                                                      they offer are crucial in
                                                      the modern world: not only
                                                      for artists, but also for
                                                      persons involved in other
                                                      valuable endeavours. Our
                                                      project aims to draw
                                                      attention to what is
                                                      happening to wildlife in
                                                      today’s world, and to the
                                                      fact that given current
                                                      trends, so destructive for
                                                      the environment, our
                                                      children will no longer
                                                      have the opportunity to
                                                      see animals such as
                                                      elephants and thousands of
                                                      other more or less popular
                                                      species in their natural
                                                      habitats.
                                                </p>
                                          </div>
                                          <div
                                                className={classNames(
                                                      'split-item-image center-content-mobile reveal-from-bottom',
                                                      imageFill &&
                                                            'split-item-image-fill',
                                                )}
                                                data-reveal-container=".split-item"
                                          >
                                                <Image
                                                      src={require('./../../assets/images/ikona1.png')}
                                                      alt="Features split 01"
                                                      width={528}
                                                      height={396}
                                                />
                                          </div>
                                    </div>

                                    <div className="split-item">
                                          <div
                                                className="split-item-content center-content-mobile reveal-from-right"
                                                data-reveal-container=".split-item"
                                          >
                                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                                      What is the value?
                                                </div>

                                                <p className="m-0">
                                                      This terrifying fact
                                                      should reach as many
                                                      people as possible. It is
                                                      through our NFTs that we
                                                      can build greater
                                                      awareness about the unique
                                                      wildlife on Borneo: a
                                                      magical place on Earth,
                                                      featuring the world’s
                                                      oldest rainforest and
                                                      rich, diverse flora and
                                                      fauna. This is why in
                                                      addition to its value for
                                                      collectors our series of
                                                      Borneo animals is also a
                                                      real contribution towards
                                                      making the Earth a better
                                                      place to live.
                                                </p>
                                          </div>
                                          <div
                                                className={classNames(
                                                      'split-item-image center-content-mobile reveal-from-bottom',
                                                      imageFill &&
                                                            'split-item-image-fill',
                                                )}
                                                data-reveal-container=".split-item"
                                          >
                                                <Image
                                                      src={require('./../../assets/images/ikona2.png')}
                                                      alt="Features split 02"
                                                      width={528}
                                                      height={396}
                                                />
                                          </div>
                                    </div>

                                    <div className="split-item">
                                          <div
                                                className="split-item-content center-content-mobile reveal-from-left"
                                                data-reveal-container=".split-item"
                                          >
                                                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                                                      JOIN THE REVOLUTION
                                                </div>
                                                {/* <h3 className="mt-0 mb-12">
                                                      Data-driven insights
                                                </h3> */}
                                                <p className="m-0">
                                                      Thanks to the success of
                                                      SAVING BORNEO WILDLIFE we
                                                      have the opportunity to
                                                      build a global community
                                                      in an authentic and
                                                      organic fashion. We are
                                                      focused on long-term
                                                      action and providing
                                                      functionality everywhere
                                                      possible in order to
                                                      create value.
                                                </p>
                                          </div>
                                          <div
                                                className={classNames(
                                                      'split-item-image center-content-mobile reveal-from-bottom',
                                                      imageFill &&
                                                            'split-item-image-fill',
                                                )}
                                                data-reveal-container=".split-item"
                                          >
                                                <Image
                                                      src={require('./../../assets/images/ikona3.png')}
                                                      alt="Features split 03"
                                                      width={528}
                                                      height={396}
                                                />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
