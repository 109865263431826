import React from 'react';
import classNames from 'classnames';
import OpenSeaLogo from '../../../assets/images/opensea-logo.svg';
import TelegramLogo from '../../../assets/images/telegram-svgrepo.svg';
import FacebookLogo from '../../../assets/images/facebook-svgrepo.svg';
import { URLS } from '../../../utils/consts';

const FooterSocial = ({ className, ...props }) => {
      const classes = classNames('footer-social', className);

      return (
            <div {...props} className={classes}>
                  <div
                        style={{
                              textDecoration: 'underline',
                              fontSize: '21px',
                              paddingBottom: '5px',
                        }}
                  >
                        OUR COMMUNITY:{' '}
                  </div>
                  <ul className="list-reset">
                        <li>
                              <a href={URLS.FB_URL} target="_blank">
                                    <img
                                          style={{
                                                width: '24px',
                                                color: 'white',
                                          }}
                                          src={FacebookLogo}
                                    />
                              </a>
                        </li>
                        <li>
                              <a href={URLS.TELEGRAM_URL} target="_blank">
                                    <img
                                          style={{
                                                width: '24px',
                                                color: 'white',
                                          }}
                                          src={TelegramLogo}
                                    />
                              </a>
                        </li>
                        <li>
                              <a href={URLS.OPEN_SEA_URL} target="_blank">
                                    <img
                                          style={{
                                                width: '24px',
                                                color: 'white',
                                          }}
                                          src={OpenSeaLogo}
                                    />
                              </a>
                        </li>
                  </ul>
            </div>
      );
};

export default FooterSocial;
