import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import OpenSeaLogo from '../../assets/images/opensea-logo.svg';
import Input from '../elements/Input';
import { URLS } from '../../utils/consts';

const propTypes = {
      ...SectionProps.types,
      split: PropTypes.bool,
};

const defaultProps = {
      ...SectionProps.defaults,
      split: false,
};

const Cta = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
}) => {
      const outerClasses = classNames(
            'cta section center-content-mobile reveal-from-bottom',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'cta-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
            split && 'cta-split',
      );

      return (
            <section {...props} className={outerClasses}>
                  <div className="container">
                        <div className={innerClasses}>
                              <div className="cta-slogan">
                                    <h3 className="m-0">
                                          SAVING BORNEO WILDLIFE NFT's ON
                                          OPENSEA
                                    </h3>
                              </div>
                              <div className="cta-action">
                                    <a
                                          style={{
                                                border: '1px solid white',
                                                padding: '10px',
                                                fontSize: '18px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontWeight: '900',
                                          }}
                                          href={URLS.OPEN_SEA_URL}
                                          target="_blank"
                                    >
                                          SEE COLLECTION
                                          <img
                                                style={{
                                                      width: '32px',
                                                      paddingLeft: '10px',
                                                }}
                                                src={OpenSeaLogo}
                                          />
                                    </a>
                              </div>
                        </div>
                  </div>
            </section>
      );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
