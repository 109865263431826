import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import Roadmap from '../../assets/images/roadmap.jpg';
import { Dialog, Grid, DialogTitle } from '@material-ui/core';
import { URLS } from '../../utils/consts';

const propTypes = {
      ...SectionProps.types,
};

const defaultProps = {
      ...SectionProps.defaults,
};

const GalleryMain = ({
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
}) => {
      const [detailsModalId, setdDetailsModalId] = useState(false);
      const [data, setData] = useState([
            {
                  src: './../../assets/images/ikona3.png',
                  name: 'Roman Level',
                  desc: 'Lorem ipsum loren dkdl flflf',
            },
            {
                  src: './../../assets/images/ikona3.png',
                  name: 'Roman Level',
                  desc: 'Lorem ipsum loren dkdl flflf',
            },
            {
                  src: './../../assets/images/ikona3.png',
                  name: 'Roman Level',
                  desc: 'Lorem ipsum loren dkdl flflf',
            },
      ]);

      const openModal = (arg) => {
            setdDetailsModalId(arg);
      };

      const closeModal = () => {
            setdDetailsModalId(false);
      };

      const outerClasses = classNames(
            'testimonial section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className,
      );

      const innerClasses = classNames(
            'testimonial-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider',
      );

      const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

      return (
            <section {...props} className={outerClasses}>
                  <h1
                        className="mt-0 mb-16 reveal-from-bottom"
                        data-reveal-delay="200"
                        style={{ textAlign: 'center' }}
                  >
                        <span className="text-color-primary">Gallery</span>
                  </h1>
                  <p
                        className="m-0 mb-32 reveal-from-bottom"
                        data-reveal-delay="400"
                        style={{ textAlign: 'center' }}
                  >
                        See our NFT's!
                  </p>
                  <div className="container-sm" id="about">
                        <div className={innerClasses}>
                              <div className="hero-content">
                                    <div className="container-xs">
                                          <div className="container">
                                                <div className={tilesClasses}>
                                                      <Grid container>
                                                            {/* 1 */}
                                                            {data.map((el) => (
                                                                  <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={4}
                                                                  >
                                                                        <div
                                                                              className="tiles-item reveal-from-right"
                                                                              data-reveal-delay="200"
                                                                        >
                                                                              <div className="tiles-item-inner">
                                                                                    <div className="testimonial-item-content">
                                                                                          <Image
                                                                                                src={require('./../../assets/images/ikona3.png')}
                                                                                                alt="Features split 03"
                                                                                                width={
                                                                                                      528
                                                                                                }
                                                                                                height={
                                                                                                      396
                                                                                                }
                                                                                          />
                                                                                    </div>
                                                                                    <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                                                                          <span className="testimonial-item-name text-color-high">
                                                                                                Roman
                                                                                                Level
                                                                                          </span>
                                                                                          <span className="text-color-low">
                                                                                                {' '}
                                                                                                /{' '}
                                                                                          </span>
                                                                                          <span className="testimonial-item-link">
                                                                                                <div
                                                                                                      style={{
                                                                                                            cursor: 'pointer',
                                                                                                      }}
                                                                                                      onClick={() =>
                                                                                                            openModal(
                                                                                                                  1,
                                                                                                            )
                                                                                                      }
                                                                                                >
                                                                                                      Details
                                                                                                </div>
                                                                                          </span>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </Grid>
                                                            ))}
                                                      </Grid>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div
                        className="reveal-from-bottom"
                        data-reveal-delay="600"
                        style={{ textAlign: 'center' }}
                  >
                        <ButtonGroup>
                              <Button
                                    tag="a"
                                    color="primary"
                                    wideMobile
                                    href={URLS.OPEN_SEA_URL}
                              >
                                    Join Sale
                              </Button>
                              <Button
                                    tag="a"
                                    color="dark"
                                    wideMobile
                                    href={
                                          '../../assets/pdf/prezentacja_borneo.pdf'
                                    }
                                    download
                              >
                                    Presentation
                              </Button>
                        </ButtonGroup>
                  </div>
                  {detailsModalId && (
                        <>
                              <Dialog
                                    onClose={closeModal}
                                    aria-labelledby="simple-dialog-title"
                                    open={detailsModalId}
                              >
                                    <DialogTitle id="simple-dialog-title">
                                          Set backup account
                                    </DialogTitle>
                              </Dialog>
                        </>
                  )}
            </section>
      );
};

GalleryMain.propTypes = propTypes;
GalleryMain.defaultProps = defaultProps;

export default GalleryMain;
